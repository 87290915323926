body {
	transition: background-color 2s ease;
	font-family: 'Indie Flower';
	font-size: 28px;
}
body > #root > div {
	height: 100vh;
}
.fa {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 30px;
	width: 60px;
	text-align: center;
	text-decoration: none;
	color: gainsboro;
  }